import React from "react"
import { Link } from "gatsby"
import { NotFound } from "../components"
import { Layout } from "../layout"


function NotFoundPage({ location }: any) {
	return (
		<Layout location={location}>
			<NotFound />
		</Layout>
	)
}

export default NotFoundPage
